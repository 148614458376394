import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IsUser } from '../utils/Common';

export default function Sidebar() {
    const currentPath = window.location.pathname;

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [iconSide, setIconSide] = useState('right');
    const [icon, setIcon] = useState("bi bi-list");

    const toggleSidebar = () => {
        setIsSidebarOpen(prevState => !prevState);
    };


    useEffect(() => {
        const body = document.querySelector('body');
        if (isSidebarOpen) {
            body.classList.add('toggle-sidebar');
            setIconSide("right");
        } else {
            body.classList.remove('toggle-sidebar');
            setIconSide("left");
        }
    }, [isSidebarOpen]);

    const toggleIcon = () => {
        setIcon(`bi-arrow-${iconSide}-short`);
    }

    return (
    <section id="sidebar" className="sidebarFull">
        <ul id="sidebar-nav" className="sidebar-nav scroll h-100 pt-0">
            <div className="d-flex align-items-center mb-3">
                <Link className="navbar-brand  mx-auto">
                  <img style={{height: "50px"}} src={process.env.REACT_APP_LOGO} className="img-fluid" alt="logo" />
                </Link>
            </div>

            <span className="toggle-sidebar-btn" onMouseOver={() => toggleIcon()} onMouseOut={() => setIcon("bi bi-list")} onClick={toggleSidebar} ><i className={icon}></i></span>
            <li className="mb-2 nav-item">
                <Link to="/stripe" className={`w-100 nav-link ${(currentPath === '/stripe') ? `active` : ''}`}>
                    <i className="bi bi-plugin"></i>
                    <span className="ms-2">Stripe Integration</span>
                </Link>
            </li>
            <li className="mb-2 nav-item">
                <Link to="/payments" className={`w-100 nav-link ${(currentPath === '/payments') ? `active` : ''}`}>
                    <i className="bi bi-credit-card-fill"></i>
                    <span className="ms-2">Payments</span>
                </Link>
            </li>
            <li className="mb-2 nav-item">
                <Link to="/rewards" className={`w-100 nav-link ${(currentPath === '/rewards' || currentPath === '/add-reward-rule') ? `active` : ''}`}>
                    <i className="bi bi-trophy-fill"></i>
                    <span className="ms-2">Rewards</span>
                </Link>
            </li>
            <li className="mb-2 nav-item">
                <Link to="/latest-offerings" className={`w-100 nav-link ${(currentPath === '/add-offer' || currentPath === '/latest-offerings' || currentPath === '/update-offer') ? `active` : ''}`}>
                    <i className="bi bi-gift-fill"></i>
                    <span className="ms-2">Latest Offerings</span>
                </Link>
            </li>
            <li className="mb-2 nav-item">
                <Link to="/addons" className={`w-100 nav-link ${(currentPath === '/addons' || currentPath === '/create-addons') ? `active` : ''}`}>
                    <i className="bi bi-bag-plus-fill"></i>
                    <span className="ms-2">Addons</span>
                </Link>
            </li>
            
            
            {!IsUser &&
                <li className="mb-2 nav-item">
                    <Link to="/push-notifications" className={`w-100 nav-link ${(currentPath === '/edit-notification' || currentPath === '/push-notifications' || currentPath === '/add-notification') ? `active` : ''}`}>
                        <i className="bi bi-bell-fill"></i>
                        <span className="ms-2">Push Notifications</span>
                    </Link>
                </li>
            }

            <li className='fs-12'>App Settings</li>
            <li><hr /></li>
            <li className="mb-2 nav-item">
                <Link to="/menu-items" className={`w-100 nav-link ${(currentPath === '/menu-items' || currentPath === '/add-menu-items') ? `active` : ''}`}>
                    <i className="bi bi-ui-checks"></i>
                    <span className="ms-2">Kitchen Menu Items</span>
                </Link>
            </li>
            <li className="mb-2 nav-item">
                <Link to="/mobile-available-products" className={`w-100 nav-link ${(currentPath === '/mobile-available-products') ? `active` : ''}`}>
                    <i className="bi bi-cart-check-fill"></i>
                    <span className="ms-2">App Visible Products</span>
                </Link>
            </li>

            {!IsUser &&
                <>
                    <li className='fs-12'>Settings</li>
                    <li><hr /></li>
                    <li className="mb-2 nav-item">
                        <Link to="/users" className={`w-100 nav-link ${(currentPath === '/users' || currentPath === '/add-user') ? `active` : ''}`}>
                            <i className="bi bi-people-fill"></i>
                            <span className="ms-2">User Managment</span>
                        </Link>
                    </li>
                </>
            }

            
        </ul>
    </section>
  )
}
